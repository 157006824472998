import classes from "./CoursePageTutorsWork.module.css";
import avantLogo from "../../Assets/Images/avantLogo.svg";
import ishaLogo from "../../Assets/Images/ishaLogo.svg";
import chargeBee from "../../Assets/Images/chargeBee.svg";
import clane from "../../Assets/Images/clane.svg";
import paga from "../../Assets/Images/paga.svg";
import robinHood from "../../Assets/Images/robinHood.svg";
import sofiLogo from "../../Assets/Images/sofiLogo.svg";
import stripe from "../../Assets/Images/stripe.svg";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";

const brands = [
  avantLogo,
  sofiLogo,
  ishaLogo,
  stripe,
  clane,
  robinHood,
  chargeBee,
  paga,
];

const CoursePageTutorsWork = () => {
  // Context
  const { courseTutors } = useContext(AppContext);

  return (
    <section className={classes.brandSection} ref={courseTutors}>
      <h4>Where some of our tutors work</h4>
      <div className={classes.logoSection}>
        {brands.map((data) => (
          <img key={data} src={data} alt="Our tutor brands" />
        ))}
      </div>
    </section>
  );
};

export default CoursePageTutorsWork;
