import { courseType } from "../../Utilities/types";
import classes from "./CourseCard.module.css";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

type CourseCardTypes = {
  data: courseType;
};

const CourseCard = ({ data }: CourseCardTypes) => {
  // Router
  const navigate = useNavigate();
  const { schoolId } = useParams();

  return (
    <div className={classes.container}>
      <img src={data?.cover_image || iseLogo} alt="School" />
      <h4>{data?.name}</h4>

      <p>{data?.description}</p>
      <div className={classes.infoSection}>
        <span>3- months</span>
        <span>Beginners friendly</span>
      </div>
      <Button
        onClick={() => {
          scrollToTheTop();
          navigate(`${data?.id}`);
        }}
      >
        Explore course
      </Button>
    </div>
  );
};

export default CourseCard;
