import Button from "../../Components/Button/Button";
import classes from "./HomePageWhoWeAre.module.css";

const communityVoices = [
  {
    comment:
      "After attending my first open mic conversation held by Ise, that was the beginning of every good story. Ise is also that community that never fails to bring genuine opportunities your way.",
    name: "Nkoro I.",
  },
  {
    comment:
      "It's an amazing community that is very close to its members and attentive. The various meetings I’ve been to were very engaging and relatable to my tech-life.",
    name: "Shalom P.",
  },
  {
    comment:
      "One of the highlights for me is the trivia, it brings the community together and we get to vibe with one another. Also, the community has given me friends which has helped my tech learning journey and I super grateful for that.",
    name: "Emediong U.",
  },
  {
    comment:
      "Joined the community from the one session of the fireside chats and it was an amazing experience. The sessions are full of impact and I've been seeing a lot of things from a different perspective since I joined.",
    name: "God’s Favour E.",
  },
];

const HomePageWhoWeAre = () => {
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <p>WHO ARE WE</p>
        <h2>
          iṣẹ́ (pronounced as{" "}
          <span>
            /ɪʃɛ/)
            <svg
              viewBox="0 0 112 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M110 18.7926C110.245 21.5857 109.112 24.4604 106.659 27.2996C104.204 30.142 100.478 32.8806 95.7113 35.345C86.1829 40.2715 72.6816 44.0075 57.4722 45.3382C42.2628 46.6688 28.3178 45.3341 18.0787 42.137C12.9567 40.5377 8.81175 38.4877 5.89978 36.1149C2.99115 33.7448 1.37693 31.1104 1.13257 28.3173C0.888206 25.5242 2.02044 22.6495 4.47331 19.8103C6.929 16.9678 10.655 14.2293 15.4215 11.7648C24.9499 6.83832 38.4512 3.10235 53.6606 1.7717C68.87 0.441044 82.8149 1.77578 93.0541 4.97288C98.1761 6.5722 102.321 8.62212 105.233 10.995C108.142 13.3651 109.756 15.9995 110 18.7926Z"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </span>
        </h2>
        <p>
          was born from the need to <span>bridge the gap</span> in remote work
          opportunities for Africans. We are dedicated to{" "}
          <span>providing quality education and empowering individuals </span>
          with all round skills, making them{" "}
          <span>more attractive candidates in the global job market</span>
        </p>

        {/* <Button type="white">Learn about us</Button> */}
      </div>

      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <p>WE BELIEVE IN THE POWER OF NUMBERS</p>
          <h2>
            <span>
              <svg
                width="133"
                height="45"
                viewBox="0 0 133 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M131.301 16.8293C131.525 19.3911 130.181 22.1312 127.182 24.9262C124.194 27.7106 119.693 30.4202 113.963 32.8846C102.515 37.8091 86.3781 41.6643 68.2739 43.2482C50.1698 44.8321 33.6085 43.8377 21.4791 40.976C15.409 39.5438 10.5055 37.657 7.07961 35.4338C3.6406 33.202 1.84128 30.737 1.61714 28.1751C1.39301 25.6132 2.73694 22.8731 5.73616 20.0781C8.72394 17.2937 13.2253 14.5842 18.9545 12.1197C30.4027 7.1952 46.5397 3.34005 64.6438 1.75614C82.748 0.172234 99.3093 1.16666 111.439 4.02839C117.509 5.46054 122.412 7.3473 125.838 9.57054C129.277 11.8023 131.077 14.2674 131.301 16.8293Z"
                  stroke="#1B695C"
                  strokeWidth="2"
                />
              </svg>
              Voice
            </span>{" "}
            from our trybe
          </h2>
        </div>

        <div className={classes.contentVoices}>
          {communityVoices.map((data) => {
            return (
              <div key={data?.name} className={classes.voice}>
                <p>{data?.comment}</p>
                <h5>{data?.name}</h5>
              </div>
            );
          })}
        </div>

        <p>
          You can be a part of the trybe
          <svg
            width="79"
            height="121"
            viewBox="0 0 79 121"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.37581 0.688948C-0.242375 1.60533 0.961731 3.71195 4.91482 8.74646C14.2701 20.4784 26.1332 31.4926 37.6962 39.0309L42.9482 42.5098L41.0597 47.2569C37.4783 56.5666 35.5695 68.314 35.6807 80.1929C35.7369 89.8943 38.3827 101.389 41.6937 106.375C41.9932 106.841 40.311 106.056 37.9642 104.693C33.7594 102.197 32.145 101.736 30.463 102.456C28.422 103.306 29.7676 104.749 37.3731 110.026C41.5795 112.959 45.8966 116.358 47.1394 117.776C48.4283 119.184 49.7992 120.525 50.2313 120.719C51.3891 121.331 53.4362 120.723 53.8845 119.699C54.1139 119.21 53.7841 116.032 53.0136 111.978C51.6079 104.037 51.5568 101.233 52.6706 94.1318C53.1485 91.3053 53.4256 88.6645 53.3317 88.2488C52.7786 85.801 48.7812 91.1217 47.7818 95.7219C47.3976 97.4591 47.0941 100.198 47.1267 101.846C47.1687 103.536 47.0453 104.923 46.8605 104.965C46.722 104.996 45.8755 103.828 45.0484 102.316C42.6747 98.0408 40.7816 88.803 40.7492 81.1404C40.6993 73.1852 41.4525 65.7765 42.9243 59.376C44.1366 53.9996 47.1457 45.1644 47.8385 45.0078C48.0232 44.9661 49.7143 45.3607 51.6215 45.8521C53.4825 46.3539 55.9856 46.9049 57.1365 47.0332C67.2687 48.2389 75.3097 43.4123 77.6918 34.8104C78.3962 32.127 78.4482 30.8531 77.9848 27.9427C77.2556 23.6414 76.1739 21.6475 73.4606 19.7363C69.6505 17.0535 63.957 17.6603 58.6647 21.3319C55.1861 23.7684 52.0348 27.2474 48.23 32.9668L44.8584 38.049L41.1362 35.5406C32.3521 29.5588 21.3042 19.574 11.289 8.57373C4.2552 0.88578 3.02887 -0.244926 1.6433 0.0681465C1.08907 0.193376 0.473784 0.478034 0.37697 0.694084L0.37581 0.688948ZM70.4258 23.0865C73.9332 24.4299 75.5932 28.7689 74.326 33.4727C72.1843 41.8261 63.5367 45.0422 52.866 41.477L49.9247 40.4911L50.7707 38.6495C52.0293 35.8408 57.3245 28.9594 59.8794 26.7316C63.8323 23.3627 67.6076 22.0242 70.4207 23.0876L70.4258 23.0865Z"
              fill="black"
            />
          </svg>
        </p>

        <div className={classes.quizSection}>
          <p>
            Join our community of 500+ techies. Connect and network with tech
            enthusiasts, get first hand updates and community benefits.
          </p>

          <div className={classes.buttonSection}>
            <Button
              type="white"
              onClick={() => {
                window.open("https://bit.ly/joinisecommunity");
              }}
            >
              Join iṣẹ́ trybe
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageWhoWeAre;
