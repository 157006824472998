import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import Loader from "../../Components/Loader/Loader";
import SectionsNav from "../../Components/SectionsNav/SectionsNav";
import { AppContext } from "../../Context/AppContext";
import { scrollToElementWithGap } from "../../HelperFunctions/scrollToTop";
import {
  useCoursesById,
  useSchool,
  useSchoolCourses,
} from "../../Hooks/useSchools";
import { courseType, navItemsType, schoolsType } from "../../Utilities/types";
import CourseDetailWhenWeStart from "../CourseDetailWhenWeStart/CourseDetailWhenWeStart";
import CoursePageCurricullum from "../CoursePageCurricullum/CoursePageCurricullum";
import CoursePageEarnACertificate from "../CoursePageEarnACertificate/CoursePageEarnACertificate";
import CoursePageHeroSection from "../CoursePageHeroSection/CoursePageHeroSection";
import CoursePageOtherCourses from "../CoursePageOtherCourses/CoursePageOtherCourses";
import CoursePageTutorsWork from "../CoursePageTutorsWork/CoursePageTutorsWork";
import CoursePageTwoWays from "../CoursePageTwoWays/CoursePageTwoWays";
import CoursePageWhatIs from "../CoursePageWhatIs/CoursePageWhatIs";
import CoursePageWhatYouNeed from "../CoursePageWhatYouNeed/CoursePageWhatYouNeed";
import CoursePageWhoShouldEnrol from "../CoursePageWhoShouldEnrol/CoursePageWhoShouldEnrol";
import HomePageAffordable from "../HomePageAffordable/HomePageAffordable";
import HomePageBuildWithEveryone from "../HomePageBuildWithEveryone/HomePageBuildWithEveryone";
import HomePageFaqs from "../HomePageFaqs/HomePageFaqs";
import SchoolPageCoursesSection from "../SchoolPageCoursesSection/SchoolPageCoursesSection";
import classes from "./CoursePage.module.css";

const CoursePage = () => {
  // Context
  const {
    courseOverview,
    courseCurricullum,
    courseTutors,
    coursePricing,
    courseRequirement,
    courseAdmission,
    courseFaqs,
  } = useContext(AppContext);

  //   Router
  const { courseId, schoolId } = useParams();

  //   Requests
  const { isLoading, data } = useCoursesById(courseId as string);
  const { isLoading: schoolCourseIsLoading, data: schoolCourses } =
    useSchoolCourses(schoolId as string);
  const { isLoading: schoolisLoading, data: schoolData } = useSchool();

  const course: courseType = data?.data;
  const otherCourses: courseType[] = useMemo(
    () =>
      schoolCourses?.data?.filter((data: courseType) => {
        return String(data?.id) !== (courseId as string);
      }),
    [schoolCourses]
  );
  const activeSchool = useMemo(
    () =>
      schoolData?.data?.find(
        (data: schoolsType) => String(data?.id) === schoolId
      ),
    [schoolData]
  );

  // States
  const [navItems, setNavItems] = useState<navItemsType[]>([
    {
      title: "Overview",
      isActive: true,
      activeComponent: null,
      onClick: () => {
        courseOverview.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Curriculum",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseCurricullum.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Tutors",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseTutors.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Pricing",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        coursePricing.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Requirement",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseRequirement.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
    {
      title: "Admission",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseAdmission.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },

    {
      title: "FAQs",
      isActive: false,
      activeComponent: null,
      onClick: () => {
        courseFaqs.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      },
    },
  ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      <CoursePageHeroSection course={course} />
      <div className={classes.sectionNav}>
        <SectionsNav navItems={navItems} setNavItems={setNavItems} />
      </div>

      <CoursePageWhatIs course={course} />
      <CoursePageCurricullum />
      <CoursePageWhoShouldEnrol />
      {/* TODO: Have conversations on the curricullum, how to send to email and the curricullum endpoint */}
      {/* TODO: Have discussions about the course ttuors endpoint */}

      <CoursePageTutorsWork />
      <CoursePageTwoWays />
      <CoursePageEarnACertificate />
      <CoursePageWhatYouNeed />

      <div ref={courseFaqs}>
        <HomePageFaqs />
      </div>

      {otherCourses?.length > 0 && (
        <CoursePageOtherCourses
          isLoading={schoolCourseIsLoading || schoolisLoading}
          courses={otherCourses}
          activeSchool={activeSchool}
        />
      )}

      <CourseDetailWhenWeStart
        courseDetail={course}
        routeToMainApp={() => {}}
      />
      <HomePageAffordable />
    </Layout>
  );
};

export default CoursePage;
