import Home from "../../v2/Containers/Home/Home";
import ErrorPage from "../Components/404/404";
import BecomeATutorForm from "../Containers/BecomeATutorForm/BecomeATutorForm";
import CoursePage from "../Containers/CoursePage/CoursePage";
import FaqLayout from "../Containers/FaqLayout/FaqLayout";
import FitQuiz from "../Containers/FitQuiz/FitQuiz";
import LaptopDonationForm from "../Containers/LaptopDonationForm/LaptopDonationForm";
import PartnershipApplicationForm from "../Containers/PartnershipApplicationForm/PartnershipApplicationForm";
import SchoolPage from "../Containers/SchoolPage/SchoolPage";
import SponsorAStudent from "../Containers/SponsorAStudent/SponsorAStudent";
import SponsorshipApplicationForm from "../Containers/SponsorshipApplicationForm/SponsorshipApplicationForm";
import WhyIseEdTech from "../Containers/WhyIseEdTech/WhyIseEdTech";
import { routes } from "./routes";

export const routeComponents = [
  {
    route: routes.HOME,
    component: <Home />,
  },
  {
    route: routes.ERROR,
    component: <ErrorPage />,
  },
  {
    route: routes.FIT_QUIZ,
    component: <FitQuiz />,
  },

  {
    route: routes.SPONSOR_A_STUDENT,
    component: <SponsorAStudent />,
  },

  {
    route: routes.SCHOOL,
    component: <SchoolPage />,
  },

  {
    route: routes.COURSE,
    component: <CoursePage />,
  },

  {
    route: routes.WHY_ISE_EDTECH,
    component: <WhyIseEdTech />,
  },

  {
    route: routes.BECOME_A_TUTOR,
    component: <BecomeATutorForm />,
  },
  {
    route: routes.SPONSOR_A_STUDENT_FORM,
    component: <SponsorshipApplicationForm />,
  },

  {
    route: routes.DONATE_A_LAPTOP,
    component: <LaptopDonationForm />,
  },

  {
    route: routes.PARTNER_WITH_US,
    component: <PartnershipApplicationForm />,
  },
  {
    route: routes.FAQS,
    component: <FaqLayout />,
  },
];
