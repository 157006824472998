import React from "react";
import Layout from "../../Components/Layout/Layout";
import HomePageAffordable from "../HomePageAffordable/HomePageAffordable";
import SchoolPageCoursesSection from "../SchoolPageCoursesSection/SchoolPageCoursesSection";
import SchoolPageDidYouKnow from "../SchoolPageDidYouKnow/SchoolPageDidYouKnow";
import SchoolPageHeroSection from "../SchoolPageHeroSection/SchoolPageHeroSection";
import SchoolPageTutors from "../SchoolPageTutors/SchoolPageTutors";
import SchoolPageWhatWeOffer from "../SchoolPageWhatWeOffer/SchoolPageWhatWeOffer";

const SchoolPage = () => {
  return (
    <Layout>
      <SchoolPageHeroSection />
      <SchoolPageCoursesSection />
      <SchoolPageDidYouKnow />
      <SchoolPageWhatWeOffer />
      <SchoolPageTutors />
      <HomePageAffordable />
    </Layout>
  );
};

export default SchoolPage;
