import classes from "./SchoolPageDidYouKnow.module.css";
import schoolPageDidYouKnow from "../../Assets/Images/schoolPageDidYouKnow.svg";

const SchoolPageDidYouKnow = () => {
  return (
    <section className={classes.container}>
      <div className={classes.textSection}>
        <h6>DID YOU KNOW?</h6>

        <h2>
          Business leaders who speak <span>"tech"</span> bridge communication
          gaps, foster innovation and build stronger teams.
        </h2>

        <div className={classes.estimates}>
          <div>
            <h3>$80,000 - $150,000</h3>
            <p>
              Estimated annual salary range of tech business leaders in the US
            </p>
          </div>

          <div>
            <h3>20+</h3>
            <p>Possible career paths</p>
          </div>
        </div>

        <p>
          Tech-savvy leaders are in high demand! A McKinsey & Company report
          revealed that 87% of CEOs believe digital skills are crucial for
          business success.
        </p>
      </div>

      <div className={classes.imagesSection}>
        <img src={schoolPageDidYouKnow} alt="DID YOU KNOW?" />
      </div>
    </section>
  );
};

export default SchoolPageDidYouKnow;
