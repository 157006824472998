import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import Loader from "../../Components/Loader/Loader";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";
import { useSchoolCourses } from "../../Hooks/useSchools";
import { routes } from "../../Utilities/routes";
import { courseType, schoolsType } from "../../Utilities/types";
import classes from "./HeaderDropdownContainer.module.css";

type HeaderDropdownContainerType = {
  schools: schoolsType[];
};

const HeaderDropdownContainer = ({ schools }: HeaderDropdownContainerType) => {
  //   States
  const [activeSchoolId, setActiveSchoolId] = useState<null | string>("1");

  //   Requests
  const { isLoading, data } = useSchoolCourses(activeSchoolId as string);

  // Memo
  const courses: courseType[] = useMemo(() => data?.data, [data]);

  //   Effects
  useEffect(() => {
    if (activeSchoolId) {
      setTimeout(() => {
        mutate(`/api/ise/v1/school/landing/${String(activeSchoolId)}/courses`);
      }, 1000);
    }
  }, [activeSchoolId]);

  console.log(courses);

  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div>
          {schools?.map((school) => {
            return (
              <div
                className={classes.school}
                key={school?.id}
                onMouseOver={() => {
                  setActiveSchoolId(String(school?.id));
                }}
              >
                <span>{school?.name}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.975 12C14.975 12.1333 14.95 12.2626 14.9 12.388C14.85 12.5133 14.7834 12.6173 14.7 12.7L10.1 17.3C9.91672 17.4833 9.68338 17.575 9.40005 17.575C9.11671 17.575 8.88338 17.4833 8.70005 17.3C8.51672 17.1166 8.42505 16.8833 8.42505 16.6C8.42505 16.3166 8.51672 16.0833 8.70005 15.9L12.6 12L8.70005 8.09995C8.51672 7.91662 8.42505 7.68328 8.42505 7.39995C8.42505 7.11662 8.51672 6.88329 8.70005 6.69995C8.88338 6.51662 9.11671 6.42495 9.40005 6.42495C9.68338 6.42495 9.91672 6.51662 10.1 6.69995L14.7 11.3C14.8 11.4 14.871 11.5083 14.913 11.625C14.955 11.7416 14.9757 11.8666 14.975 12Z"
                    fill="#2E2E2E"
                  />
                </svg>
              </div>
            );
          })}
        </div>
        <div>
          {isLoading ? (
            <Loader />
          ) : courses?.length > 0 ? (
            courses?.map((course) => {
              return (
                <Link
                  to={`/${activeSchoolId}/${course?.id}`}
                  className={classes.school}
                  key={course?.id}
                  onClick={scrollToTheTop}
                >
                  {course?.name}
                </Link>
              );
            })
          ) : (
            <p className={classes.noCourses}>
              No courses available for the school yet
            </p>
          )}
        </div>
      </div>
      <div className={classes.bottomSection}>
        <span
          onClick={() => {
            window.open(
              `${window.location.origin}${routes.FIT_QUIZ}?step=intro`
            );
          }}
        >
          Not sure of your career path? take our career fit quiz
        </span>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.475 12C15.475 12.1333 15.45 12.2626 15.4 12.388C15.35 12.5133 15.2834 12.6173 15.2 12.7L10.6 17.3C10.4167 17.4833 10.1834 17.575 9.90005 17.575C9.61671 17.575 9.38338 17.4833 9.20005 17.3C9.01672 17.1166 8.92505 16.8833 8.92505 16.6C8.92505 16.3166 9.01672 16.0833 9.20005 15.9L13.1 12L9.20005 8.09995C9.01672 7.91662 8.92505 7.68328 8.92505 7.39995C8.92505 7.11662 9.01672 6.88329 9.20005 6.69995C9.38338 6.51662 9.61671 6.42495 9.90005 6.42495C10.1834 6.42495 10.4167 6.51662 10.6 6.69995L15.2 11.3C15.3 11.4 15.371 11.5083 15.413 11.625C15.455 11.7416 15.4757 11.8666 15.475 12Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default HeaderDropdownContainer;
