import classes from "./SchoolPageTutors.module.css";

const tutors = [
  {
    name: "Gloria Alado",
    course: "Customer Support Manager",
    image: null,
  },
  {
    name: "Project Manager",
    course: "Customer Support Manager",
    image: null,
  },
  {
    name: "Gloria Alado",
    course: "Customer Support Manager",
    image: null,
  },
];

const SchoolPageTutors = () => {
  return (
    <section className={classes.container}>
      <h4>THE TALENTS</h4>

      <h2>Meet the tutors</h2>

      <div className={classes.tutorsSection}>
        {tutors.map((data, i) => {
          return (
            <div
              key={i}
              className={classes.tutor}
              style={
                data?.image
                  ? {
                      backgroundImage: `url(${data?.image})`,
                      backgroundColor: "none",
                    }
                  : { backgroundColor: "#b3b3b3", backgroundImage: "none" }
              }
            >
              <div>
                <h5>{data.name}</h5>
                <p>{data?.course}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SchoolPageTutors;
