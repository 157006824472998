import CollaborateFormsLayout from "../../Components/CollaborateFormsLayout/CollaborateFormsLayout";
import classes from "../BecomeATutorForm/BecomeATutorForm.module.css";
import collaborateBreadcrumbImage from "../../Assets/Icons/collaborateBreadcrumbImage.svg";
import Input from "../../Components/Input/Input";
import DropdownWithSearch from "../../../Components/DropdownWithSearch/DropdownWithSearch";
import Button from "../../Components/Button/Button";
import TextArea from "../../Components/TextArea/TextArea";
import { useContext, useEffect, useState } from "react";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import ApplicationSuccessfulBody from "../PartnershipApplicationForm/ApplicationSuccessfulBody";
import { FormsContext } from "../../Context/FormsContext";

const SponsorshipApplicationForm = () => {
  // COntext
  const {
    sponsorshipFormData,
    setSponsorshipFormData,
    postSponsorshipFormData,
    postSponsorshipFormRequest,
    setPosySponsorshipFormRequest,
  } = useContext(FormsContext);

  // Utils
  const breadCrumbProps = {
    image: collaborateBreadcrumbImage,
    array: [
      { title: "Sponsorship", route: "/sponsor-a-student" },
      {
        title: "Sponsorship application form ",
        route: "/sponsor-a-student/sponsorship-application-form",
      },
    ],
  };

  const formChangeHandler = (e: any) => {
    setSponsorshipFormData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const [sponsorshipCategory, setSponsorshipcategory] = useState("");
  const [contactmedium, setContactMedium] = useState("");

  // Effects
  useEffect(() => {
    if (contactmedium) {
      setSponsorshipFormData((prevState) => {
        return { ...prevState, contact_medium: contactmedium };
      });
    }

    // eslint-disable-next-line
  }, [contactmedium]);

  useEffect(() => {
    if (sponsorshipCategory) {
      setSponsorshipFormData((prevState) => {
        return { ...prevState, sponsorship_type: sponsorshipCategory };
      });
    }

    // eslint-disable-next-line
  }, [sponsorshipCategory]);

  return (
    <CollaborateFormsLayout
      breadCrumbProps={breadCrumbProps}
      header="Sponsor and invest in tech education"
      paragraph="Support and empower aspiring tech minds through sponsorship. Make a lasting impact by contributing through monetary donations or awarding scholarships."
    >
      <section className={classes.container}>
        {postSponsorshipFormRequest?.data && (
          <AcceptedModal
            onClick={() => {
              setPosySponsorshipFormRequest((prevState) => {
                return { ...prevState, data: null };
              });
            }}
            body={<ApplicationSuccessfulBody />}
          />
        )}
        <Input
          label="Full name"
          isRequired
          placeholder="e.g John Doe"
          errorMessage="Please check the name and try again"
          value={sponsorshipFormData?.fullname}
          name="fullname"
          onChange={formChangeHandler}
        />
        <Input
          label="Email address"
          isRequired
          placeholder="e.g yourname@gmail.com"
          type="email"
          errorMessage="Incorrect format. Try name@gmail.com"
          value={sponsorshipFormData?.email}
          name="email"
          onChange={formChangeHandler}
        />
        <Input
          label="Phone number"
          placeholder="e.g 0906xxxx456"
          type="phone"
          errorMessage="Incorrect format. Try name@gmail.com"
          value={sponsorshipFormData?.phone}
          name="phone"
          onChange={formChangeHandler}
        />
        <Input
          label="Name of company/organization"
          placeholder="Enter company name"
          type="text"
          value={sponsorshipFormData?.organization}
          name="organization"
          onChange={formChangeHandler}
        />

        <DropdownWithSearch
          label="Sponsorship type/category"
          options={["High level", "Mid level", "Low level"]}
          title="Select category"
          selected={sponsorshipCategory}
          setSelected={setSponsorshipcategory}
        />

        <Input
          label="How many student(s) would you like to sponsor? (it can be a range)"
          isRequired
          placeholder="Enter number of students "
          type="text"
          errorMessage="Enter number of students to continue"
          value={sponsorshipFormData?.number_of_sponsors}
          name="number_of_sponsors"
          onChange={formChangeHandler}
        />

        <TextArea
          label="Sponsorship objective/criteria"
          placeholder="Tell us your sponsorship goal and selection criteria"
          value={sponsorshipFormData?.sponsorship_criteria}
          name="sponsorship_criteria"
          onChange={formChangeHandler}
        />

        <DropdownWithSearch
          label="How do you want us to reach you?"
          isRequired
          options={["Phone", "Social Media"]}
          title="Select"
          selected={contactmedium}
          setSelected={setContactMedium}
        />

        <div className={classes.buttonSection}>
          <Button
            onClick={() => {
              postSponsorshipFormData();
            }}
            loading={postSponsorshipFormRequest?.isLoading}
          >
            Submit
          </Button>
        </div>
      </section>
    </CollaborateFormsLayout>
  );
};

export default SponsorshipApplicationForm;
