import Layout from "../../Components/Layout/Layout";
import WhyIseCollaborateWithUs from "../WhyIseCollaborateWithUs/WhyIseCollaborateWithUs";
import WhyIseHeroSection from "../WhyIseHeroSection/WhyIseHeroSection";
import WhyIseOurStory from "../WhyIseOurStory/WhyIseOurStory";
import WhyIseWhatWeOffer from "../WhyIseWhatWeOffer/WhyIseWhatWeOffer";

const WhyIseEdTech = () => {
  return (
    <Layout>
      <WhyIseHeroSection />
      <WhyIseOurStory />
      <WhyIseWhatWeOffer />
      <WhyIseCollaborateWithUs />
    </Layout>
  );
};

export default WhyIseEdTech;
