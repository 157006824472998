import { useContext } from "react";
import Button from "../../Components/Button/Button";
import { AppContext } from "../../Context/AppContext";
import { courseObjectiveType, courseType } from "../../Utilities/types";
import classes from "./CoursePageWhatIs.module.css";

type CoursePageWhatIsTypes = {
  course: courseType;
};

const CoursePageWhatIs = ({ course }: CoursePageWhatIsTypes) => {
  // Context
  const { courseOverview } = useContext(AppContext);
  return (
    <section className={classes.container} ref={courseOverview}>
      <div className={classes.textSection}>
        <h4>WHAT IS</h4>
        <h2>{course?.name}</h2>

        {/* TODO: There is no "what is" field courses */}

        <p>
          is the process of creating the user interface and user experience
          (UI/UX) of a website or application. It involves building the parts of
          a website or app that users can see and interact with such as layout,
          colours, typography, images, and responsiveness.
        </p>
      </div>

      <div className={classes.objectives}>
        <h5>Your learning outcomes</h5>
        <ul>
          {JSON.parse(course.course_objective)?.map(
            (data: courseObjectiveType) => {
              return (
                <li key={data?.id}>
                  <span>
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L9 17L19 7"
                        stroke="#2E2E2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span>{data?.value}</span>
                </li>
              );
            }
          )}
        </ul>

        <Button>Enrol now</Button>
        <p>
          100% money back guarantee if you change your mind within 48 hours. See
          refund policy
        </p>
      </div>
    </section>
  );
};

export default CoursePageWhatIs;
