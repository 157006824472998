import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import classes from "./404.module.css";
import errorImage from "../../Assets/Images/404Image.svg";
import Layout from "../Layout/Layout";

type ErrorPageProps = {
  title?: string;
  description?: string;
};

const ErrorPage = ({ title, description }: ErrorPageProps) => {
  // Navigate
  const navigate = useNavigate();
  return (
    <Layout>
      <section className={classes.container}>
        <div className={classes.innerContainer}>
          <img src={errorImage} alt="Page not found" />
          <h4>{title || "Oops! Page Not Found"}</h4>
          <p>
            {description ||
              "It seems you've ventured off the beaten path. The page you're looking for may have been relocated or doesn't exist. Don't worry; we can help you get back on track."}
          </p>
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Back to homepage
          </Button>
        </div>
      </section>
    </Layout>
  );
};

export default ErrorPage;
