import { useContext } from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import { AppContext } from "../../Context/AppContext";
import classes from "./CoursePageCurricullum.module.css";

const CoursePageCurricullum = () => {
  // Context
  const { courseCurricullum } = useContext(AppContext);
  return (
    <section className={classes.container} ref={courseCurricullum}>
      <div className={classes.textSection}>
        <h4>WELL CURATED CURRICULUM</h4>
        <h2>Structured lessons, videos, and audios for optimal learning</h2>
      </div>
      <div className={classes.formSection}>
        <Input
          label="Get the curriculum for free"
          placeholder="Enter email address"
        />

        <Button type="black">Request for curriculum</Button>
      </div>
    </section>
  );
};

export default CoursePageCurricullum;
