import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { notificationsType } from "../Utilities/types";

type AppContextTypes = {
  courseOverview: MutableRefObject<HTMLDivElement | null>;
  coursesRef: MutableRefObject<HTMLDivElement | null>;
  courseCurricullum: MutableRefObject<HTMLDivElement | null>;
  courseTutors: MutableRefObject<HTMLDivElement | null>;
  coursePricing: MutableRefObject<HTMLDivElement | null>;
  courseRequirement: MutableRefObject<HTMLDivElement | null>;
  courseAdmission: MutableRefObject<HTMLDivElement | null>;
  schoolsRef: MutableRefObject<HTMLDivElement | null>;
  courseFaqs: MutableRefObject<HTMLDivElement | null>;
  scrollTOView: (ref: MutableRefObject<HTMLDivElement | null>) => void;
  setNotifications: Dispatch<SetStateAction<notificationsType>>;
};

type AppContextProviderTypes = {
  children: React.ReactNode;
};

export const AppContext = createContext({} as AppContextTypes);

const AppContextProvider = ({ children }: AppContextProviderTypes) => {
  // Refs
  const courseOverview = useRef<HTMLDivElement | null>(null);
  const courseCurricullum = useRef<HTMLDivElement | null>(null);
  const courseTutors = useRef<HTMLDivElement | null>(null);
  const coursePricing = useRef<HTMLDivElement | null>(null);
  const courseRequirement = useRef<HTMLDivElement | null>(null);
  const courseAdmission = useRef<HTMLDivElement | null>(null);
  const courseFaqs = useRef<HTMLDivElement>(null);
  const schoolsRef = useRef<HTMLDivElement>(null);
  const coursesRef = useRef<HTMLDivElement>(null);

  const scrollTOView = (ref: MutableRefObject<HTMLDivElement | null>) => {
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  // States
  const [notifications, setNotifications] = useState<notificationsType>(null);

  return (
    <AppContext.Provider
      value={{
        courseOverview,
        courseCurricullum,
        courseTutors,
        coursePricing,
        courseFaqs,
        schoolsRef,
        courseRequirement,
        courseAdmission,
        scrollTOView,
        setNotifications,
        coursesRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
