import classes from "./QuizLayout.module.css";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import fitQuiz1 from "../../Assets/Images/fitQuiz1.svg";
import ProgressBar from "../ProgressBar/ProgressBar";
import { useSearchParams } from "react-router-dom";
import { TOTAL_QUIZ_STEPS } from "../../Utilities/constants";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { quizDataTypes } from "../../Utilities/types";
import RadioButton from "../RadioButton/RadioButton";
import TextArea from "../TextArea/TextArea";

type QuizLayoutTypes = {
  header: string;
  caption?: string | null;
  description?: string | null;
  image?: string | null;
  body: React.ReactNode | "multiple-choice" | "answer";
  option?: string[];
  id: string;
  quizData: quizDataTypes;
  setQuizData: Dispatch<SetStateAction<quizDataTypes>>;
};

const QuizLayout = (props: QuizLayoutTypes) => {
  // Router
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step");

  //   States
  const [options, setOptions] = useState(
    props?.option?.map((data) => {
      return { answer: data, isActive: false };
    })
  );

  //   Memos
  const selectedOption = useMemo(
    () => options?.filter((data) => data.isActive),
    [options]
  );

  //   Effects
  useEffect(() => {
    if (
      (props?.option && props.body === "multiple-choice") ||
      (props?.option && props.body === "multiple-multiple-choice")
    ) {
      setOptions(
        props?.option?.map((data) => {
          return { answer: data, isActive: false };
        })
      );
    }

    // eslint-disable-next-line
  }, [props.option]);

  useEffect(() => {
    if (selectedOption && props.body === "multiple-choice") {
      props.setQuizData((prevState) => {
        return { ...prevState, [props.id]: selectedOption[0]?.answer };
      });
    }
    if (selectedOption && props.body === "multiple-multiple-choice") {
      props.setQuizData((prevState) => {
        return {
          ...prevState,
          [props.id]: [...selectedOption?.map((data) => data.answer)],
        };
      });
    }

    // eslint-disable-next-line
  }, [selectedOption]);

  const bodyContent =
    props.body === "multiple-choice" ? (
      <div className={classes.optionContainer}>
        {options?.map((answer, index) => {
          return (
            <div key={answer?.answer}>
              <RadioButton
                checked={answer?.isActive}
                onChange={(e) => {
                  const optionsCopy = options?.map((data, i) => {
                    if (index === i) {
                      return { ...data, isActive: e.target.checked };
                    } else {
                      return { ...data, isActive: false };
                    }
                  });
                  setOptions(optionsCopy);
                }}
              />
              <span>{answer.answer}</span>
            </div>
          );
        })}
      </div>
    ) : props.body === "multiple-multiple-choice" ? (
      <div className={classes.optionContainer}>
        {options?.map((answer, index) => {
          return (
            <div key={answer?.answer}>
              <Checkbox
                isChecked={answer?.isActive}
                onChange={(e) => {
                  const optionsCopy = options?.map((data, i) => {
                    if (index === i) {
                      return { ...data, isActive: e };
                    } else {
                      return { ...data, isActive: data?.isActive };
                    }
                  });
                  setOptions(optionsCopy);
                }}
              />
              <span>{answer.answer}</span>
            </div>
          );
        })}
      </div>
    ) : props.body === "answer" ? (
      <Input
        placeholder="E.g John"
        onChange={(e) => {
          props.setQuizData((prevState) => {
            return { ...prevState, [props.id]: e.target.value };
          });
        }}
        value={props.quizData[props.id] as string}
      />
    ) : props.body === "long-answer" ? (
      <TextArea
        placeholder="E.g John"
        onChange={(e) => {
          props.setQuizData((prevState) => {
            return { ...prevState, [props.id]: e.target.value };
          });
        }}
        value={props.quizData[props.id] as string}
      />
    ) : (
      props.body
    );

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <img src={iseLogo} alt="Ise Logo" />
      </div>

      {Number(step) > 0 && (
        <div className={classes.progressBar}>
          <ProgressBar
            percentage={(Number(step) / TOTAL_QUIZ_STEPS) * 100}
            primaryColor="#DCD7FE"
            secondaryColor="#664EFE"
            notShowPercentage
          />
        </div>
      )}

      <div className={classes.body}>
        <div className={classes.textSection}>
          {props.caption && <p className={classes.caption}>{props.caption}</p>}
          <h1 className={classes.headerText}>{props.header}</h1>
          {props.description && (
            <p className={classes.description}>{props.description}</p>
          )}

          <div className={classes.bodyContainer}>{bodyContent}</div>
          {Number(step) > 0 && (
            <div className={classes.buttonSection}>
              <Button
                type="secondary"
                onClick={() => {
                  setSearchParams((prevState) => {
                    if (step === "1") {
                      return {
                        ...prevState,
                        step: `intro`,
                      };
                    } else {
                      return {
                        ...prevState,
                        step: `${Number(step as string) - 1}`,
                      };
                    }
                  });
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 16L3 12M3 12L7 8M3 12L21 12"
                    stroke="#664EFE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Back</span>
              </Button>

              <Button
                disabled={!props?.quizData[props.id] ? true : false}
                onClick={() => {
                  setSearchParams((prevState) => {
                    if (Number(step) >= TOTAL_QUIZ_STEPS) {
                      return {
                        ...prevState,
                        step: `complete`,
                      };
                    } else {
                      return {
                        ...prevState,
                        step: `${Number(step as string) + 1}`,
                      };
                    }
                  });
                }}
              >
                <span>
                  {String(TOTAL_QUIZ_STEPS) === step ? "Submit" : "Next"}
                </span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 8L21 12M21 12L17 16M21 12L3 12"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
          )}
        </div>
        <div className={classes.imagesSection}>
          <img src={props.image || fitQuiz1} alt={props.header} />
        </div>
      </div>
    </section>
  );
};

export default QuizLayout;
