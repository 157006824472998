import classes from "./CoursePageEarnACertificate.module.css";
import certificate from "../../Assets/Images/certificate.svg";
import curstomerSuccess from "../../Assets/Icons/curstomerSuccess.svg";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";

const stats = [
  {
    country: "Nigeria",
    value: 50,
    pay: "₦6M",
  },
  {
    country: "United Kingdon",
    value: 25,
    pay: "£45,000",
  },
  {
    country: "United States",
    value: 75,
    pay: "$89,000",
  },
];

const CoursePageEarnACertificate = () => {
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <p>EARN A CERTIFICATE</p>
        <h2>
          Get industry{" "}
          <span>
            recognized
            <svg
              width="243"
              height="48"
              viewBox="0 0 243 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M241.514 13.4347C241.606 14.4789 241.106 15.7044 239.715 17.1211C238.33 18.5308 236.177 20.0084 233.273 21.5176C227.475 24.5317 218.908 27.5606 208.17 30.422C186.71 36.1408 156.769 41.1416 123.442 44.0574C90.1145 46.9731 59.7602 47.2474 37.6328 45.3421C26.5609 44.3888 17.5978 42.8936 11.3644 40.9321C8.2432 39.95 5.86559 38.8689 4.25757 37.7209C2.64163 36.5673 1.9366 35.4472 1.84524 34.403C1.75389 33.3588 2.25371 32.1334 3.64478 30.7167C5.02903 29.3069 7.18278 27.8293 10.086 26.3201C15.8841 23.3061 24.4514 20.2772 35.1896 17.4157C56.6499 11.697 86.5907 6.6961 119.918 3.78035C153.245 0.864598 183.599 0.590341 205.727 2.49563C216.799 3.44898 225.762 4.94417 231.995 6.9056C235.116 7.88774 237.494 8.96887 239.102 10.1168C240.718 11.2704 241.423 12.3905 241.514 13.4347Z"
                stroke="#664EFE"
                strokeWidth="2"
              />
            </svg>
          </span>
        </h2>
      </div>

      <div className={classes.body}>
        <div>
          <p>
            Stand out from the crowd with the iṣẹ́ School certificate that
            showcases your skills and knowledge. The certificate validates your
            expertise and boosts your career prospects.
          </p>
          <img src={certificate} alt="EARN A CERTIFICATE" />
        </div>

        <div>
          <h2>
            <img
              src={curstomerSuccess}
              alt="What are customer success managers earning?"
            />
            <span>What are customer success managers earning?</span>
          </h2>
          <p>
            Here are some average salary ranges for project managers in
            different countries:
          </p>

          <div className={classes.stats}>
            {stats?.map((data) => {
              return (
                <div className={classes.stat}>
                  <span>{data?.country}</span>
                  <ProgressBar percentage={data?.value} notShowPercentage />
                  <span>{data?.pay}/year</span>
                </div>
              );
            })}
          </div>
          <p>Projected 5 year growth: +8.00%</p>
          <p>
            Data as of June 2024; cross-referenced with Glassdoor, LinkedIn,
            Indeed and Payscale
          </p>
        </div>
      </div>
    </section>
  );
};

export default CoursePageEarnACertificate;
