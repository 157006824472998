import CollaborateFormsLayout from "../../Components/CollaborateFormsLayout/CollaborateFormsLayout";
import classes from "./BecomeATutorForm.module.css";
import collaborateBreadcrumbImage from "../../Assets/Icons/collaborateBreadcrumbImage.svg";
import Input from "../../Components/Input/Input";
import DropdownWithSearch from "../../../Components/DropdownWithSearch/DropdownWithSearch";
import FileUploadInput from "../../Components/FileUploadInput/FileUploadInput";
import Button from "../../Components/Button/Button";
import { useContext, useEffect, useState } from "react";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import ApplicationSuccessfulBody from "../PartnershipApplicationForm/ApplicationSuccessfulBody";
import { FormsContext } from "../../Context/FormsContext";

const BecomeATutorForm = () => {
  // COntext
  const {
    tutorFormData,
    tutorFormRequest,
    setTutorFormData,
    postTutorFormData,
    setTutorFormRequest,
  } = useContext(FormsContext);

  // Utils
  const breadCrumbProps = {
    image: collaborateBreadcrumbImage,
    array: [
      { title: "Become a tutor", route: "/" },
      {
        title: "Tutor application form ",
        route: "/become-a-tutor/tutor-application-form",
      },
    ],
  };

  const formChangeHandler = (e: any) => {
    setTutorFormData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  // States
  const [experience, setExperience] = useState("");
  const [expertise, setexpertise] = useState("");
  const [experienceInField, setExperienceInField] = useState("");
  const [education, setEducation] = useState(false);
  const [contactMedium, setContactMedium] = useState(false);

  // Effects
  useEffect(() => {
    if (experience)
      setTutorFormData((prevState) => {
        return { ...prevState, yearsOfExperience: experience };
      });

    if (expertise)
      setTutorFormData((prevState) => {
        return { ...prevState, expertise: experience };
      });

    if (experienceInField)
      setTutorFormData((prevState) => {
        return { ...prevState, experienceInField: experienceInField };
      });

    if (education)
      setTutorFormData((prevState: any) => {
        return { ...prevState, educationLevel: education };
      });

    if (contactMedium)
      setTutorFormData((prevState: any) => {
        return { ...prevState, contact_medium: contactMedium };
      });

    // eslint-disable-next-line
  }, [experience, expertise, experienceInField, education, contactMedium]);

  return (
    <CollaborateFormsLayout
      breadCrumbProps={breadCrumbProps}
      header="Train the next tech generation"
      paragraph="Be a part of transforming the tech landscape. Become a tutor and inspire the next tech generation. Please fill the form carefully."
    >
      <section className={classes.container}>
        {tutorFormRequest?.data && (
          <AcceptedModal
            body={<ApplicationSuccessfulBody />}
            onClick={() => {
              setTutorFormRequest((prevState) => {
                return { ...prevState, data: null };
              });
            }}
          />
        )}
        <Input
          label="Full name"
          isRequired
          placeholder="e.g John Doe"
          errorMessage="Please check the name and try again"
          value={tutorFormData?.fullname}
          name="fullname"
          onChange={formChangeHandler}
        />
        <Input
          label="Email address"
          isRequired
          placeholder="e.g yourname@gmail.com"
          type="email"
          errorMessage="Incorrect format."
          value={tutorFormData?.email}
          name="email"
          onChange={formChangeHandler}
        />
        <Input
          label="Phone"
          isRequired
          placeholder="e.g 0906xxxx456"
          type="phone"
          errorMessage="Incorrect format. Try name@gmail.com"
          value={tutorFormData?.phone}
          name="phone"
          onChange={formChangeHandler}
        />
        <Input
          label="LinkedIn profile"
          isRequired
          placeholder="Enter linkedIn profile link"
          type="text"
          errorMessage="Incorrect link. Please check and try again"
          value={tutorFormData?.linkedIn}
          name="linkedIn"
          onChange={formChangeHandler}
        />
        <DropdownWithSearch
          label="Do you have any teaching experience?"
          isRequired
          options={["Yes", "No, but i'd love to teach"]}
          title="Select"
          selected={experience}
          setSelected={setExperience}
        />
        <DropdownWithSearch
          label="Area of expertise"
          isRequired
          options={[
            "Project Management",
            "DevOps Engineering",
            "Data Analytics",
            "Data Science",
            "Talent Acquisition",
            "Backend Development",
            "Frontend Development",
            "Customer Success Management",
            "Talent Acuistion",
            "Customer Sucess Management",
          ]}
          title="Select area of Expertise"
          selected={expertise}
          setSelected={setexpertise}
        />
        <DropdownWithSearch
          label="Years of experience in the field selected?"
          isRequired
          options={[
            "1-3years",
            "4-7years",
            "8-10years",
            "No, but I'd love to teach",
          ]}
          title="Select area of Expertise"
          selected={experienceInField}
          setSelected={setExperienceInField}
        />

        <FileUploadInput
          label="Upload resume"
          isRequired
          errorMessage="Incorrect format."
          accept=",.doc, .docx,.ppt, .pptx,.txt,.pdf"
          onChange={(e: any) => {
            setTutorFormData((prevState) => {
              return { ...prevState, resume: e.target.files[0] };
            });
          }}
        />

        <DropdownWithSearch
          label="Level of education"
          isRequired
          options={["Bachelor's Degree ", "Masters Degree", "Ph.D", "Diploma"]}
          title="Select level of education"
          selected={education}
          setSelected={setEducation}
        />
        <DropdownWithSearch
          label="How do you want us to reach you?"
          isRequired
          options={["Phone", "Email", "Social Media"]}
          title="Select"
          selected={contactMedium}
          setSelected={setContactMedium}
        />

        <div className={classes.buttonSection}>
          <Button
            onClick={() => {
              postTutorFormData();
            }}
            loading={tutorFormRequest?.isLoading}
          >
            Submit
          </Button>
        </div>
      </section>
    </CollaborateFormsLayout>
  );
};

export default BecomeATutorForm;
