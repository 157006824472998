import Button from "../../Components/Button/Button";
import classes from "./SchoolPageHeroSection.module.css";
import schoolPageHero from "../../Assets/Images/schoolPageHero.svg";
import avantLogo from "../../Assets/Images/avantLogo.svg";
import ishaLogo from "../../Assets/Images/ishaLogo.svg";
import chargeBee from "../../Assets/Images/chargeBee.svg";
import clane from "../../Assets/Images/clane.svg";
import paga from "../../Assets/Images/paga.svg";
import robinHood from "../../Assets/Images/robinHood.svg";
import sofiLogo from "../../Assets/Images/sofiLogo.svg";
import stripe from "../../Assets/Images/stripe.svg";

const brands = [
  avantLogo,
  sofiLogo,
  ishaLogo,
  stripe,
  clane,
  robinHood,
  chargeBee,
  paga,
];

const SchoolPageHeroSection = () => {
  return (
    <section className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.textSection}>
          <div className={classes.topSection}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="url(#pattern0_15239_6080)" />
              <defs>
                <pattern
                  id="pattern0_15239_6080"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use transform="scale(0.00195312)" />
                </pattern>
              </defs>
            </svg>

            <span>PUSHING BUSINESSES FORWARD</span>
          </div>
          <h1>iṣẹ́ School of Business and Management</h1>
          <p>
            Gain essential management skills and drive business success with our
            comprehensive programs.
          </p>

          <Button>Explore courses</Button>
        </div>

        <div className={classes.imagesSection}>
          <img src={schoolPageHero} alt="Welcome to Ise school" />
        </div>
      </div>

      <div className={classes.brandSection}>
        <h4>Where some of our tutors work</h4>
        <div className={classes.logoSection}>
          {brands.map((data) => (
            <img key={data} src={data} alt="Our tutor brands" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SchoolPageHeroSection;
