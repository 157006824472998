import Button from "../../Components/Button/Button";
import classes from "./HomePageAffordable.module.css";
import work1 from "../../Assets/Images/work1.png";
import work2 from "../../Assets/Images/work2.png";
import learn1 from "../../Assets/Images/learn1.png";
import learn2 from "../../Assets/Images/learn2.png";
import succeed1 from "../../Assets/Images/suceed1.png";
import succeed2 from "../../Assets/Images/suceed2.png";
import learnIcon from "../../Assets/Images/learnIcon.png";
import succeedIcon from "../../Assets/Images/succeedIcon.png";
import workIcon from "../../Assets/Images/workIcon.png";
import { routeToMainApp } from "../../HelperFunctions/routeToMainApp";

const mission = [
  {
    title: "Learn",
    images: [learn1, learn2],
    icon: learnIcon,
  },
  {
    title: "Work",
    images: [work1, work2],
    icon: workIcon,
  },
  {
    title: "Succeed",
    images: [succeed1, succeed2],
    icon: succeedIcon,
  },
];

const missionRepeated = [...mission, ...mission];

const HomePageAffordable = () => {
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <p>WE ARE AFFORDABLE</p>
        <h2>
          Skill up on any course with an initial sum of{" "}
          <span>
            ₦15,000
            <svg
              width="200"
              height="48"
              viewBox="0 0 200 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M198.439 14.9911C198.543 16.1749 198.111 17.4677 197.009 18.8806C195.903 20.299 194.169 21.7739 191.816 23.2713C187.111 26.2648 180.118 29.2318 171.314 31.9911C153.72 37.5049 129.105 42.1253 101.646 44.5276C74.1864 46.93 49.1426 46.6543 30.859 44.2793C21.709 43.0908 14.3071 41.3833 9.15406 39.2522C6.57632 38.1861 4.61275 37.0348 3.27739 35.83C1.94718 34.6298 1.29715 33.4317 1.19358 32.2479C1.09001 31.0641 1.52211 29.7712 2.6237 28.3583C3.72957 26.94 5.46337 25.4651 7.81683 23.9676C12.5215 20.9741 19.5145 18.0072 28.3191 15.2478C45.9125 9.73407 70.5279 5.1137 97.9872 2.71132C125.446 0.30895 150.49 0.5847 168.774 2.95962C177.924 4.14815 185.326 5.85571 190.479 7.98679C193.057 9.05283 195.02 10.2042 196.355 11.409C197.686 12.6091 198.336 13.8073 198.439 14.9911Z"
                stroke="#664EFE"
                strokeWidth="2"
              />
            </svg>
          </span>
        </h2>
        <p>
          If you've made it this far, you must be at least a little curious.
          Sign up and take the first step toward your goals.
        </p>
        <Button onClick={routeToMainApp}> Start learning</Button>
      </div>

      <div className={classes.missionContainer}>
        {missionRepeated.map((data) => {
          return (
            <div key={data.title} className={`${classes.mission}`}>
              <div>
                <img src={data.icon} alt={data.title} />
                <span>{data?.title}</span>
              </div>
              <div className={classes.imageSection}>
                {data.images?.map((image, j) => {
                  return <img src={image} alt={data?.title} key={j} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HomePageAffordable;
