import classes from "./SchoolCard.module.css";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import Button from "../../../Components/Button/Button";
import { schoolsType } from "../../Utilities/types";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

type SchoolCardType = {
  data: schoolsType;
};

const SchoolCard = ({ data }: SchoolCardType) => {
  // Navigate
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <h4>{data?.name}</h4>
      <p>
        <span>Courses in this school</span>: Frontend Development, Backend
        Development, Cloud DevOps, Cloud DevSecOps
      </p>
      <img src={data?.image || iseLogo} alt="School" />
      <p>{data?.description}</p>
      <div className={classes.infoSection}>
        <span>3-months</span>
        <span>Beginners friendly</span>
      </div>
      <Button
        onClick={() => {
          navigate(`/${data?.id}`);
          scrollToTheTop();
        }}
      >
        Explore course
      </Button>
    </div>
  );
};

export default SchoolCard;
