import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import classes from "./CoursePageTwoWays.module.css";

const paymentTypes = [
  {
    tag: "Best value",
    title: "Instalment payment",
    price: "40,000",
    caption: "Get 10% discount of the total tuition fee when you pay at once. ",
  },
  {
    tag: "Most flexible",
    title: "Full payment",
    price: "15,000",
    caption:
      "Spread the tuition payment across 3 months when you pay in instalments.",
  },
];

const CoursePageTwoWays = () => {
  // Context
  const { coursePricing } = useContext(AppContext);

  return (
    <section className={classes.container} ref={coursePricing}>
      <div className={classes.header}>
        <p>QUALITY LEARNING AT AFFORDABLE COST</p>
        <h2>
          2 ways to fund your{" "}
          <span>
            tech future
            <svg
              width="221"
              height="47"
              viewBox="0 0 221 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M219.411 14.2216C219.506 15.3034 219.043 16.5331 217.801 17.9229C216.559 19.3123 214.619 20.7651 211.995 22.2462C206.752 25.2049 198.987 28.1607 189.236 30.9348C169.75 36.4783 142.533 41.2403 112.212 43.893C81.892 46.5457 54.262 46.5821 34.1092 44.5066C24.0244 43.468 15.8638 41.9055 10.1871 39.9021C7.34551 38.8992 5.18306 37.8053 3.71867 36.6526C2.25389 35.4997 1.58441 34.369 1.48977 33.2873C1.39513 32.2056 1.85812 30.9759 3.10043 29.5861C4.34241 28.1966 6.28204 26.7438 8.9063 25.2628C14.1489 22.3041 21.9142 19.3482 31.6654 16.5742C51.1516 11.0307 78.3682 6.26865 108.689 3.61595C139.009 0.963251 166.639 0.92684 186.792 3.00235C196.877 4.04096 205.037 5.60349 210.714 7.60688C213.556 8.60971 215.718 9.70366 217.183 10.8563C218.647 12.0093 219.317 13.1399 219.411 14.2216Z"
                stroke="#664EFE"
                stroke-width="2"
              />
            </svg>
          </span>
        </h2>

        <p>
          Everyone should have the opportunity for growth. That’s why we offer a
          range of payment options.
        </p>
      </div>

      <div className={classes.paymentOptions}>
        {paymentTypes?.map((data) => {
          return (
            <div key={data?.tag}>
              <div className={classes.tag}>
                <svg
                  width="123"
                  height="52"
                  viewBox="0 0 123 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 49.235V-15C0 -16.1046 0.895429 -17 2 -17H121C122.105 -17 123 -16.1046 123 -15V49.0154C123 50.4384 121.556 51.4062 120.24 50.8651L68.9547 29.7812C68.5133 29.5997 68.0215 29.5821 67.5682 29.7315L2.62602 51.1345C1.33221 51.5609 0 50.5973 0 49.235Z"
                    fill="#FFD952"
                  />
                </svg>
                <span>{data?.tag}</span>
              </div>

              <h3>{data?.title}</h3>
              <h2>
                <span>₦</span>
                {data?.price}
              </h2>
              <p>{data?.caption}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CoursePageTwoWays;
