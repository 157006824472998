import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "./Containers/404/404";
import BecomeATutorForm from "./Containers/BecomeATutorForm/BecomeATutorForm";
import LaptopDonationForm from "./Containers/LaptopDonationForm/LaptopDonationForm";
import PartnershipApplicationForm from "./Containers/PartnershipApplicationForm/PartnershipApplicationForm";
import SponsorshipApplicationForm from "./Containers/SponsorshipApplicationForm/SponsorshipApplicationForm";
// import Blog from "./Pages/Blog";
// import BlogContent from "./Pages/BlogContent";
import CourseDetail from "./Pages/CourseDetail";
import FaqPage from "./Pages/FaqPage";
import HomePage from "./Pages/HomePage";
import SchoolOfBusiness from "./Pages/SchoolOfBusiness";
import SponsorAStudent from "./Pages/SponsorAStudent";
import WhyIseEdTech from "./Pages/WhyIseEdTech";
import { routeComponents } from "./v2/Utilities/routeComponents";

function App() {
  return (
    <Routes>
      {routeComponents.map((component, i) => {
        return <Route path={component.route} element={component.component} />;
      })}
    </Routes>
  );
}

export default App;
