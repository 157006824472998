import { backend_url } from "../Utilities/global";
import useGetHook from "./useGetHook";

export const useSchool = () => {
  const url = `${backend_url}/api/ise/v1/school`;

  return useGetHook(url, {
    revalidateOnFocus: false,
  });
};

export const useSchoolCourses = (id: string) => {
  const url = id
    ? `${backend_url}/api/ise/v1/school/landing/${id}/courses`
    : null;

  return useGetHook(url, {
    revalidateOnFocus: false,
  });
};

export const useCoursesById = (id: string) => {
  const url = id ? `${backend_url}/api/ise/v1/courses/${id}` : null;

  return useGetHook(url, {
    revalidateOnFocus: false,
  });
};
